import React from 'react';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import Feedback from './Feedback';
import feedbackIcon from '../../../assets/images/general/feedback.png';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

function FeedbackModal() {
  const customStyles = {
    overlay: {
      zIndex: '7',
      backgroundColor: 'rgba(220, 220, 220, 0.8)'
    },
    content: {
      top: '10px',
      height: 'auto',
      minHeight: '580px',
      padding: '1%',
      position: 'relative',
      width: '60%',
      margin: '4% 17%',
      borderRadius: '4%'
    }
  }
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <Grid>
        <Tooltip title={i18next.t('feedback.title')} aria-label='Feedback'>
          <Button
            onClick={openModal}
            style={{
              bottom: 30,
              right: 30,
              zIndex: 6,
              position: 'fixed',
              opacity: 0.8,
            }}
          >
            <img src={feedbackIcon} alt='' style={{ height: 50, width: 50 }} />
          </Button>
        </Tooltip>
      </Grid>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <Feedback hide={closeModal}></Feedback>
      </Modal>
    </>
  );
}

export default withTranslation()(FeedbackModal);
