import React, { useState, useEffect } from 'react';
import { addResizingListener } from '../../../utils/Responsiveness';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import { requestPasswordReset } from '../../../api/UserApi'
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next';

function ForgotPassword() {

    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [apiResponse, setApiResponse] = useState({});

    const handleChange = () => (event) => {
        setEmail(event.target.value);
    };

    const onSubmit = () => {
        setApiResponse({});
        requestPasswordReset(email, setApiResponse);
        handleNotificationOpen();
    }

    const handleNotificationOpen = () => {
        setOpen(true);
    };

    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        addResizingListener(setIsMobile);
    }, []);

    return (
        <>
            <Grid
                container
                direction='column'
                wrap='nowrap'
                justify='flex-start'
                alignItems='center'
                style={{
                    minHeight: '70vh',
                    maxHeight: '70vh',
                    maxWidth: isMobile ? '80vw' : '50vw',
                    minWidth: isMobile ? '80vw' : '50vw',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}>

                <Grid
                    container
                    direction='column'
                    alignItems='center'
                    style={{
                        marginTop: 15,
                    }}
                >
                    <Typography variant='h4'>{t('forgotpwd.title')}</Typography>
                    <Typography variant='h6'>{t('forgotpwd.subtitle')}</Typography>
                </Grid>

                <Grid container={true} spacing={3}
                    style={{
                        marginTop: 15,
                    }}>
                    <Grid item={true} xs={12}>
                        <InputLabel htmlFor='email'>{t('forgotpwd.email')}</InputLabel>
                        <OutlinedInput
                            id='email'
                            fullWidth
                            type='text'
                            value={email}
                            onChange={handleChange()}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    style={{
                        marginTop: 30,
                    }}
                >
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={onSubmit}
                    >{t('forgotpwd.button')} </Button>
                </Grid>
            </Grid>

            {apiResponse.requestId === undefined && apiResponse !== 'failure' && apiResponse.message === undefined &&
                <Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='warning'>
                        {t('message.processing')}
					</MuiAlert>
                </Snackbar>
            }
            {apiResponse === 'success' &&
                <Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='success'>
                        {t('message.pwdForgotSuccess')}
					</MuiAlert>
                </Snackbar>
            }
            {apiResponse.message !== undefined &&
                <Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='error'>
                        {apiResponse.message}
                    </MuiAlert>
                </Snackbar>
            }
            {apiResponse === 'failure' &&
                <Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='error'>
                        {t('message.pwdForgotFail')}
					</MuiAlert>
                </Snackbar>
            }
        </>
    );
};

export default ForgotPassword;