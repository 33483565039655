import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import StoreMarker from './marker/StoreMarker';
import IStore from '../../../../types/dto/store';

const API_MAPS_KEY = 'AIzaSyC_Iar0MhMhzAg6GvzzMH4gIMduAUtICzU';
const DEFAULT_MAP_LOCATION = { lat: 45.5088916, lng: -73.58873280000002 };
const DEFAULT_ZOOM = 14;

const MyMapComponent = ({
  stores
}: {
  stores: IStore[];
}) => {
  const [openMarkerId, setOpenMarkerId] = useState<number | null>(null); // Track open marker id
  const [mapLocation, setMapLocation] = useState(DEFAULT_MAP_LOCATION);

  const showCurrentLocation = () => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setMapLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      null,
      { enableHighAccuracy: true }
    );
  };

  const handleMarkerClick = (markerId: number) => {
    setOpenMarkerId(markerId === openMarkerId ? null : markerId); // Toggle marker card
  };

  const url = 'https://fonts.googleapis.com/css?family=Roboto';
  const preventFontInjection = () => {
    // FIX: STOCATE-885. See: https://github.com/google-map-react/google-map-react/issues/836
    const head = document.getElementsByTagName('head')[0];
    const insertBefore = head.insertBefore;
    head.insertBefore = (newElement, referenceElement) => {
      const injectingFont =
        (newElement as unknown as HTMLLinkElement).href &&
        (newElement as unknown as HTMLLinkElement).href.indexOf(url) === 0;
      if (injectingFont) return newElement;
      insertBefore.call(head, newElement, referenceElement);
      return newElement;
    };
  };

  useEffect(() => {
    preventFontInjection();
    showCurrentLocation();
  }, []);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: API_MAPS_KEY }}
        center={mapLocation} // DefautlCenter => "defaultCenter prop changed. You can't change default props."
      defaultZoom={DEFAULT_ZOOM}
    >
      {stores.map((store) => {
        return (
          <StoreMarker
            key={store.id}
            store={store}
            lat={store.coordinates.latitude} lng={store.coordinates.longitude}
            isOpen={store.id === openMarkerId} // Pass isOpen prop to StoreMarker
            onMarkerClick={() => handleMarkerClick(store.id)} // Pass onMarkerClick handler
          />
        );
      })}
    </GoogleMapReact>
  );
};

const StoresMap = ({
  stores,
  isVisible,
}: {
  stores: IStore[];
  isVisible: boolean;
}) => {
  return (
    // isVisible prop is used to hide the component while keeping it mounted
    <div
      data-cy='storesMap'
      style={{ display: isVisible ? 'block' : 'none', height: '100%' }}
    >
      <MyMapComponent stores={stores} />
    </div>
  );
};

export default React.memo(StoresMap);