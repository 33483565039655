import { makeStyles } from '@material-ui/core';

// These styles are shared between Browse.tsx and Library.tsx, since they have
// roughly the same layout. If you want to use specific makeStyles in either
// of the two files (i.e styles that aren't shared), look into upgrading to
// MUI v5 and using the styled() api.
// https://github.com/mui-org/material-ui/issues/11517#issuecomment-835792292
export default makeStyles(() => ({
  // Need fixed height for mobile
  leftListPanel: {
    minHeight: '87vh',
    paddingRight: '1.5em',
    paddingLeft: '1.5em',
  },
  rightMapPanel: {
    minHeight: '87vh',
  },
  // These styles allow the entity scroll list to match the height of the card/map panel
  listScrollContainer: {
    position: 'relative',
    height: '100%',
  },
  listScrollColumn: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
}));
